import { APIClient } from "./API/APIClient";
import { RevistaFolder } from "./Metadata/RevistaFolder";

export class MainPage {

    private static Instance: MainPage;
    private Reviste: RevistaFolder[];

    constructor() {
        this.NavbarShrink();
        $(window).on("scroll", this.NavbarShrink);
        this.NavbarCollapse();
        this.ShowEducratesReviste1();
    }

    public async ShowEducratesReviste1() {
        this.Reviste = await this.FetchEducratesReviste();
        const reviste: RevistaFolder[] = this.Reviste;
        const tabsContainer = document.getElementById('tabsContainer');
        if (tabsContainer) {
            const revistesByYear: { [key: string]: RevistaFolder[] } = {};
            reviste.forEach((folder) => {
                const year = folder.Name.split('_')[0];
                if (!revistesByYear[year]) {
                    revistesByYear[year] = [];
                }
                revistesByYear[year].push(folder);
            });
            let tabsHTML = '<ul class="nav nav-tabs" id="magazineTabs" role="tablist">';
            let tabContentHTML = '<div class="tab-content mt-2 list-group" id="magazineTabContent">';
            Object.keys(revistesByYear).forEach((year, index) => {
                const tabId = `tab_${year}`;
                const tabPaneId = `tabPane_${year}`;
                tabsHTML += `<li class="nav-item"><a class="nav-link ${index === 0 ? 'active' : ''}" id="${tabId}-tab" data-toggle="tab" href="#${tabPaneId}" role="tab" aria-controls="${tabPaneId}" aria-selected="${index === 0 ? 'true' : 'false'}">${year}</a></li> `;
                tabContentHTML += `<div class="tab-pane fade ${index === 0 ? 'show active' : ''}" id="${tabPaneId}" role="tabpanel" aria-labelledby="${tabId}-tab">`;
                revistesByYear[year].forEach((folder) => {
                    folder.Reviste.sort((a, b) => {
                        const monthA = parseInt(a.Name.split('_')[1]);
                        const monthB = parseInt(b.Name.split('_')[1]);
                        return monthA - monthB;
                    });
                    let previousMonth = '';
                    folder.Reviste.forEach((magazine, magIndex) => {
                        const month = folder.Name.split('_')[1];
                        const monthLabels: Record<string, string> = {
                            '01': 'Ianuarie',
                            '02': 'Februarie',
                            '03': 'Martie',
                            '04': 'Aprilie',
                            '05': 'Mai',
                            '06': 'Iunie',
                            '07': 'Iulie',
                            '08': 'August',
                            '09': 'Septembrie',
                            '10': 'Octombrie',
                            '11': 'Noiembrie',
                            '12': 'Decembrie',
                        };
                        const monthLabel = monthLabels[month];
                        if (monthLabel !== previousMonth || magIndex === 0) {
                            if (previousMonth !== '') {
                                tabContentHTML += '</div></div>';
                            }
                            tabContentHTML += `<div class="accordion mt-2" id="accordion_${month}">`;
                            tabContentHTML += `<button class="btn btn-link collapsed list-group-item list-group-item-action list-group-item-light p-3 type="button" data-bs-toggle="collapse" data-bs-target="#collapse_${month}" aria-expanded="false" aria-controls="collapse_${month}">${monthLabel}<i class="bi bi-caret-down float-right"></i></button>`;
                            tabContentHTML += `<div id="collapse_${month}" class="collapse row accordion-collapse collapse" aria-labelledby="heading_${month}" data-bs-parent="#accordion_${month}">`;
                            previousMonth = monthLabel;
                        }
                        tabContentHTML +=
                            `<div class="col-auto mb-3 revista-element-template mt-2">
                            <div class="card" style="width: 15rem;">
                                <div class="card-body text-center">
                                    <img src="assets/img/educrates.jpg"
                                        class="card-img-top mx-auto m-2" alt="...">
                                    <div class="card-body text-center">
                                        <h5 class="card-title">${magazine.Name}</h5>
                                        <a href="${magazine.Link}"
                                            class="btn btn-success mt-auto align-self-start revista-link" target="_blank">Descarcă<i class="bi bi-file-earmark-arrow-down m-2"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>`;
                    });
                    tabContentHTML += '</div></div>';
                });
                tabContentHTML += '</div>';
            });
            tabsHTML += '</ul>';
            tabContentHTML += '</div>';
            tabsContainer.innerHTML = tabsHTML + tabContentHTML;
        }
    }

    public async ShowEducratesReviste() {
        this.Reviste = await this.FetchEducratesReviste();
        const reviste: RevistaFolder[] = this.Reviste;
        const tabsContainer = document.getElementById('tabsContainer');
        if (tabsContainer) {
            const revistesByYear: { [key: string]: RevistaFolder[] } = {};
            reviste.forEach((folder) => {
                const year = folder.Name.split('_')[0];
                if (!revistesByYear[year]) {
                    revistesByYear[year] = [];
                }
                revistesByYear[year].push(folder);
            });
            let tabsHTML = '<ul class="nav nav-tabs" id="magazineTabs" role="tablist">';
            let tabContentHTML = '<div class="tab-content mt-2 list-group" id="magazineTabContent">';
            Object.keys(revistesByYear).forEach((year, index) => {
                const tabId = `tab_${year}`;
                const tabPaneId = `tabPane_${year}`;
                tabsHTML += `<li class="nav-item"><a class="nav-link ${index === 0 ? 'active' : ''}" id="${tabId}-tab" data-toggle="tab" href="#${tabPaneId}" role="tab" aria-controls="${tabPaneId}" aria-selected="${index === 0 ? 'true' : 'false'}">${year}</a></li> `;
                tabContentHTML += `<div class="tab-pane fade ${index === 0 ? 'show active' : ''}" id="${tabPaneId}" role="tabpanel" aria-labelledby="${tabId}-tab">`;
                revistesByYear[year].forEach((folder) => {
                    folder.Reviste.sort((a, b) => {
                        const monthA = parseInt(a.Name.split('_')[1]);
                        const monthB = parseInt(b.Name.split('_')[1]);
                        return monthA - monthB;
                    });
                    let previousMonth = '';
                    folder.Reviste.forEach((magazine) => {
                        const month = folder.Name.split('_')[1];
                        const monthLabels: Record<string, string> = {
                            '01': 'Ianuarie',
                            '02': 'Februarie',
                            '03': 'Martie',
                            '04': 'Aprilie',
                            '05': 'Mai',
                            '06': 'Iunie',
                            '07': 'Iulie',
                            '08': 'August',
                            '09': 'Septembrie',
                            '10': 'Octombrie',
                            '11': 'Noiembrie',
                            '12': 'Decembrie',
                        };
                        const monthLabel = monthLabels[month];
                        if (monthLabel !== previousMonth) {
                            tabContentHTML += `<footer class="blockquote-footer m-2"> ${monthLabel}</footer>`;
                            previousMonth = monthLabel;
                        }
                        tabContentHTML += `<p><a href="${magazine.Link}" class="list-group-item list-group-item-action " title="Descarcă" target="_blank"><i class="bi bi-book-fill m-2"></i> ${magazine.Name}<i class="bi bi-download float-right"></i></a></p>`;
                    });
                });
                tabContentHTML += '</div>';
            });
            tabsHTML += '</ul>';
            tabContentHTML += '</div>';
            tabsContainer.innerHTML = tabsHTML + tabContentHTML;
        }
    }


    private async FetchEducratesReviste(): Promise<RevistaFolder[]> {
        const response = await APIClient.Instance.FetchEducratesReviste();
        if (response.data !== null) {
            return response.data;
        } else {
            return [];
        }
    }

    public NavbarShrink() {
        let navbarCollapsible = $("#mainNav");
        if (!navbarCollapsible.length) {
            return;
        }
        if ($(window).scrollTop() === 0) {
            navbarCollapsible.removeClass("navbar-shrink");
        } else {
            navbarCollapsible.addClass("navbar-shrink");
        }
    }
    public NavbarCollapse() {
        let navbarToggler = $(".navbar-toggler");
        let responsiveNavItems = $("#navbarResponsive .nav-link");
        responsiveNavItems.on("click", function () {
            if (navbarToggler.is(":visible")) {
                navbarToggler.click();
            }
        });
    }

    public static Initialize(): void {
        if (MainPage.Instance != null) {
            throw new Error("MainPage already initialized");
        }
        MainPage.Instance = new MainPage();
        console.log("MainPage initialized");
    }
}

declare global {
    interface Window {
        MainPage: any;
    }
}
window.MainPage = MainPage;
