import { Revista } from "../Metadata/Revista";
import { RevistaFolder } from "../Metadata/RevistaFolder";
import { APIResponse } from "./APIResponse";
import { APIStatus } from "./APIStatus";

export class APIClient {

    static _Instance: APIClient;

    public static get Instance(): APIClient {
        if (!APIClient._Instance) {
            APIClient._Instance = new APIClient();
        }
        return APIClient._Instance;
    }

    private constructor() {
        APIClient._Instance = this;
    }

    private async LoadGET(url: string): Promise<Response | null> {
        let response = await fetch(url);
        if (response.status == 200) {
            return response;
        }
        console.error("API Error: " + response.status + " " + response.statusText);
        return null;
    }

    private async LoadPOST(url: string, data: any): Promise<Response | null> {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: data,
            });

            if (response.status === 200) {
                return response;
            } else {
                console.error("API Error: " + response.status + " " + response.statusText);
                return null;
            }
        } catch (error) {
            console.error("An error occurred:", error);
            return null;
        }
    }

    async FetchEducratesReviste(): Promise<APIResponse<RevistaFolder[]>> {
        let response: Response | null = await this.LoadGET("https://api.edituraedu.ro/GetEducratesReviste");
        let parsed: APIResponse<RevistaFolder[]> = await this.NormalizeResponse<RevistaFolder[]>(response);
        if (parsed.data != null) {
            parsed.data = this.NormalizeArray(parsed.data, RevistaFolder);
            for (let i = 0; i < parsed.data.length; i++) {
                let folder = parsed.data[i];
                folder.Reviste = this.NormalizeArray(folder.Reviste, Revista);
            }
        }
        else {
            parsed.data = [];
        }
        return parsed;
    }

    /**
 * Takes a return value from Load and returns a normalized APIResponse.+
 * @param {Response|null} response 
 * @returns {Promise<APIResponse<T>}
 */
    private async NormalizeResponse<T>(response: Response | null): Promise<APIResponse<T>> {
        if (response == null) {
            let result: APIResponse<T> = new APIResponse<T>();
            result.status = APIStatus.ERROR;
            return result;
        }
        let result: APIResponse<T> = await response.json() as APIResponse<T>;
        return result;
    }

    private NormalizeArray<T extends object>(array: T[], type: (new () => T)): T[] {
        if (array == null) {
            return [];
        }
        return array.map((item) => {
            let obj: any = Object.assign(new type(), item);
            if (typeof obj.UpdateCache === "function") {
                obj.UpdateCache();
            }
            return obj;
        });
    }
}
declare global {
    interface Window {
        APIClient: any;
    }
}
window.APIClient = APIClient;